/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, ReactNode } from 'react'
import { SkillProficiencyMapping } from 'src/contracts/skills'
import { mqMax } from 'src/GlobalStyle'
import { Box } from 'src/components/opportunities-manager/Box'
import { Application } from 'src/contracts/opportunities-manager/contracts'
import { Nullable } from 'src/types'
import { convertTimestampToDateString } from 'src/utils/dates'
import { useQuery } from 'src/utils/useQuery'
import { getRate } from 'src/utils/application'
import { BackLink } from '../BackLink'
import { Button } from '../Button'
import { Dot } from '../Dot'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { LayoutWithStickyColumn } from '../layout/LayoutWithStickyColumn'
import { PageWidthWrapper } from '../layout/PageWidthWrapper'
import { OpportunitySummary } from './OpportunitySummary'
import { ApplicationName } from './ApplicationDetailsComponent.styles'
import { MsaInfo } from './MsaInfo'

import { useCompany } from 'src/hooks/useCompany'

type ApplicationDetailProps = { name: string; value: ReactNode; dataTestId?: string }

const ApplicationDetail: FunctionComponent<React.PropsWithChildren<ApplicationDetailProps>> = ({ name, value, dataTestId }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                flex: 1;
            `}
        >
            <div
                css={css`
                    color: ${theme.colors.gray_4};
                    margin-bottom: 8px;
                `}
            >
                {name}
            </div>
            <div
                css={css`
                    font-weight: 500;
                `}
                data-testid={dataTestId}
            >
                {value || '–'}
            </div>
        </div>
    )
}

type ApplicationDetailsComponentProps = {
    application: Nullable<Application>
    backlinkPath: string
    handleCvDownload: () => void
    opportunityId?: string
    isSmeCloud?: boolean
    isVMCCloud?: boolean
}

const ApplicationDetailsComponent: FunctionComponent<React.PropsWithChildren<ApplicationDetailsComponentProps>> = ({
    application,
    backlinkPath,
    children,
    handleCvDownload,
    opportunityId,
    isSmeCloud = false,
    isVMCCloud = false,
}) => {
    const theme = useTheme()
    const query = useQuery()
    const { company } = useCompany()

    const wasSubmittedBy = Boolean(
        application?.submittedBy?.firstName && application?.submittedBy?.lastName && application?.submittedBy?.email,
    )

    const shouldShowMSAWarning =
        !!application && !application.partnerName && !application?.smeCloud && !!company && !company.msaSigned && !isVMCCloud

    return (
        <div
            css={css`
                background-color: ${theme.colors.beige_1};
                padding: 24px;
                min-height: calc(100vh - 57px);
                ${mqMax[1]} {
                    padding: 24px 16px;
                }
            `}
        >
            <PageWidthWrapper>
                <BackLink
                    style={css`
                        margin-bottom: 20px;
                    `}
                    path={decodeURIComponent(query.get('prevPath') || backlinkPath)}
                    text='Back to list'
                    dataTestId='back'
                />
            </PageWidthWrapper>
            <LayoutWithStickyColumn
                style={css`
                    padding-bottom: 75px;
                    overflow-x: hidden;
                `}
                firstCol={
                    application ? (
                        <Fragment>
                            <Box data-testid='application-details-header'>
                                <ApplicationName>
                                    {application.firstName} {application.lastName}
                                </ApplicationName>
                                {shouldShowMSAWarning && <MsaInfo marginBottom='6px' />}
                            </Box>
                            <Box>{children}</Box>
                            {application.partnerName && (
                                <Box>
                                    <FlexBox
                                        css={css`
                                            margin-bottom: 16px;
                                        `}
                                    >
                                        {!isSmeCloud && <ApplicationDetail name='MSA' value={application.msa ? 'Yes' : 'No'} />}
                                        <ApplicationDetail name='Partner' value={application.partnerName} />
                                    </FlexBox>
                                    <FlexBox>
                                        <ApplicationDetail
                                            name='Submitted by'
                                            value={
                                                wasSubmittedBy ? (
                                                    <Fragment>
                                                        {application.submittedBy.firstName} {application.submittedBy.lastName}
                                                        <br />
                                                        {application.submittedBy.email}
                                                    </Fragment>
                                                ) : (
                                                    '–'
                                                )
                                            }
                                        />

                                        <ApplicationDetail
                                            name='Submitted on'
                                            value={application.submittedOn ? convertTimestampToDateString(application.submittedOn) : '–'}
                                        />
                                    </FlexBox>
                                </Box>
                            )}
                            {application.cvFileName && (
                                <Box>
                                    <Button
                                        variant='linkForm'
                                        style={css`
                                            font-weight: 500;
                                        `}
                                        onClick={handleCvDownload}
                                        dataTestId='application-cv'
                                    >
                                        {application.cvFileName}
                                    </Button>
                                </Box>
                            )}
                            <Box>
                                <h6>Complete Information</h6>
                                <FlexBox
                                    alignItems='center'
                                    css={css`
                                        margin-bottom: 16px;
                                    `}
                                >
                                    <ApplicationDetail name='Role' value={application.role} />
                                    {application.partnerName ? (
                                        <ApplicationDetail
                                            name='Location'
                                            value={`${application.country}${application.city ? `, ${application.city}` : ''}`}
                                            dataTestId='location'
                                        />
                                    ) : (
                                        <ApplicationDetail name='Rate' value={getRate(application)} />
                                    )}
                                </FlexBox>
                                <FlexBox
                                    alignItems='center'
                                    css={css`
                                        margin-bottom: 16px;
                                    `}
                                >
                                    <ApplicationDetail
                                        name='Available from'
                                        value={application.availableFrom ? convertTimestampToDateString(application.availableFrom) : '–'}
                                    />
                                    <ApplicationDetail
                                        name='Available to'
                                        value={application.availableTo ? convertTimestampToDateString(application.availableTo) : '–'}
                                    />
                                </FlexBox>
                                <FlexBox alignItems='center'>
                                    {application.partnerName ? (
                                        <Fragment>
                                            <ApplicationDetail name='Rate' value={getRate(application)} />
                                            {!isSmeCloud && (
                                                <ApplicationDetail
                                                    name='TA Rate'
                                                    value={
                                                        application.internalRate && application.currency
                                                            ? `${application.internalRate} ${application.currency}/hour`
                                                            : '–'
                                                    }
                                                    dataTestId='internal-rate'
                                                />
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <ApplicationDetail name='Country' value={application.country} />
                                            <ApplicationDetail name='City' value={application.city} />
                                        </Fragment>
                                    )}
                                </FlexBox>
                            </Box>
                            {application?.comment && (
                                <Box>
                                    <h6>Notes</h6>
                                    <p
                                        css={css`
                                            white-space: pre-wrap;
                                        `}
                                    >
                                        {application.comment}
                                    </p>
                                </Box>
                            )}
                            {application.skills.length > 0 && (
                                <Box>
                                    <h6>Rate Required Skills (optional step)</h6>
                                    <FlexBox
                                        alignItems='center'
                                        css={css`
                                            flex-wrap: wrap;
                                            ${mqMax[0]} {
                                                align-items: flex-start;
                                                flex-direction: column;
                                            }
                                        `}
                                    >
                                        {application.skills.map((skill, index) => (
                                            <FlexBox
                                                css={css`
                                                    white-space: pre;
                                                    line-height: 24px;
                                                `}
                                                key={skill.name}
                                            >
                                                <strong>{skill.name}: </strong>
                                                <span
                                                    css={css`
                                                        color: ${theme.colors.gray_4};
                                                    `}
                                                >
                                                    {SkillProficiencyMapping[skill.rate]}
                                                </span>
                                                {application.skills.length - 1 > index && (
                                                    <Dot
                                                        style={css`
                                                            margin: 0 8px;
                                                            ${mqMax[0]} {
                                                                display: none;
                                                            }
                                                        `}
                                                    />
                                                )}
                                            </FlexBox>
                                        ))}
                                    </FlexBox>
                                </Box>
                            )}
                        </Fragment>
                    ) : null
                }
                secondCol={
                    opportunityId ? (
                        <div
                            css={css`
                                border: 1px solid ${theme.colors.beige_3};
                                border-radius: 4px;
                                padding: 24px;

                                ${mqMax[2]} {
                                    margin-bottom: 24px;
                                }
                            `}
                        >
                            <div>
                                <OpportunitySummary opportunityId={opportunityId} isVMCCloud={isVMCCloud} />
                            </div>
                        </div>
                    ) : null
                }
            />
        </div>
    )
}

export { ApplicationDetailsComponent }
