/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { saveAs } from 'file-saver'
import { Fragment, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { UseFormSetError, UseFormTrigger } from 'react-hook-form'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { ReduxContext } from 'src/redux/Store'
import userImage from 'src/assets/images/user.svg'
import { Button } from 'src/components/Button'
import { blackToVioletDarkFilter, blackToWhiteFilter, IconButton } from 'src/components/Icon'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { ContentLoader } from 'src/components/layout/Loader'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { StepsIndicator } from 'src/components/steps-indicator/StepsIndicator'
import { StepStatus } from 'src/components/steps-indicator/types'
import { mqMin } from 'src/GlobalStyle'
import { history } from 'src/history'
import { Nullable } from 'src/types'
import { useIsMobile } from 'src/utils/hooks'
import { useLogger } from 'src/utils/useLogger'
import { LOCATION_MESSAGE } from 'src/validation/validation-messages'
import { getOpportunitySummary } from 'src/api/opportunities-manager/api'
import { OpportunitySummary } from 'src/contracts/opportunities-manager/contracts'
import {
    applyWithApplication,
    applyWithCv,
    applyWithSpecialist,
    getApplicationCv,
    getShareMySpecialistsSpecialist,
    getOpportunityApplications,
    removeApplication,
    updateApplication,
    updateApplicationCv,
    updateApplicationSkills,
} from '../../../api/open-opportunities/api'
import { UpdateApplicationRequestBody } from '../../../contracts/open-opportunities/contracts'
import { ApplicationSuccess } from '../ApplicationSuccess'
import { ApplicationSummary } from '../ApplicationSummary'
import { CandidatesListColumn } from '../CandidatesListColumn'
import { LocationField, useCompleteInformationForm } from '../CompleteInformationForm'
import { OpportunitySummaryColumn } from '../OpportunitySummaryColumn'
import { useRateSkillsForm } from '../RateSkillsForm'
import { ADD, INIT, REMOVE, SELECT_APPLICATION, SET_STEP, UPDATE_APPLICATION_CV } from './redux/actions'
import { initialState, reducer } from './redux/reducer'
import { Action, ApplicationData, CurrentStep, State } from './redux/types'
import { getNextStep, getPreviousStep } from './redux/utils'
import { SubscriptionLayer } from '../SubscriptionOverlay'

const steps = ['Complete information', 'Rate skills', 'Review and apply']

const ApplicationWizard = () => {
    const theme = useTheme()
    const { opportunityId } = useParams<{ opportunityId: string }>()
    const [params] = useSearchParams()
    const log = useLogger('error')
    const { addError } = useNotifications()
    const location = useLocation()

    const [isSendingApplication, setIsSendingApplication] = useState(false)
    const [isUpdatingApplication, setIsUpdatingApplication] = useState(false)

    const [state, dispatch] = useReducer<(state: State, action: Action) => State>(reducer, initialState)
    const [opportunity, setOpportunity] = useState<Nullable<OpportunitySummary>>(null)

    const [shouldShowWarningBanner, setShouldShowWarningBanner] = useState(false)

    const applicationIdParam = params.get('applicationId')

    const isVmcCloud = location.pathname.includes('open-vacancies')

    const {
        selectors: {
            featureFlags: { shareCandidate, payments },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (opportunity) {
            setShouldShowWarningBanner(!opportunity?.availableForCurrentCompany)
        }
    }, [opportunity])

    useEffect(() => {
        if (opportunityId) {
            getOpportunitySummary(opportunityId)
                .then(setOpportunity)
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, log, opportunityId])

    const selectedApplication = useMemo(
        () => state.applications.find(application => application.applicationId === state.selectedApplicationId) || null,
        [state.applications, state.selectedApplicationId],
    )

    const showApplicationCvDropzone = useCallback(
        (application: UpdateApplicationRequestBody) => {
            dispatch({
                type: UPDATE_APPLICATION_CV,
                payload: {
                    applicationId: state.selectedApplicationId,
                    isSendingCv: false,
                    isUpdatingCv: true,
                    application,
                },
            })
        },
        [state.selectedApplicationId],
    )

    const handleApplicationCvChange = useCallback(
        (
            files: Array<File> | File,
            application: UpdateApplicationRequestBody,
            trigger: UseFormTrigger<UpdateApplicationRequestBody>,
            setError: UseFormSetError<UpdateApplicationRequestBody>,
            changeFocus: (name: LocationField) => void,
        ) => {
            if (state.selectedApplicationId && ((Array.isArray(files) && files.length > 0) || (!Array.isArray(files) && files))) {
                const cvsData = new FormData()
                if (Array.isArray(files)) {
                    for (const file of files) {
                        cvsData.append('file', file)
                    }
                } else {
                    cvsData.append('file', files)
                }
                dispatch({
                    type: UPDATE_APPLICATION_CV,
                    payload: {
                        applicationId: state.selectedApplicationId,
                        isSendingCv: true,
                        isUpdatingCv: true,
                        application: { ...selectedApplication, ...application },
                    },
                })
                let cvFileName = ''
                updateApplicationCv(state.selectedApplicationId, cvsData)
                    .then(data => {
                        cvFileName = data.cvFileName
                        dispatch({ type: UPDATE_APPLICATION_CV, payload: { ...data, isSendingCv: false, isUpdatingCv: false } })
                    })
                    .catch(err => {
                        log(err)
                        addError()
                        showApplicationCvDropzone({ ...selectedApplication, ...application })
                    })
                    .finally(() => {
                        if (
                            selectedApplication?.applicationId &&
                            (selectedApplication?.firstName !== application.firstName ||
                                selectedApplication?.lastName !== application.lastName ||
                                selectedApplication?.role !== application.role ||
                                selectedApplication?.preferredRate !== application.preferredRate ||
                                selectedApplication?.availableFrom !== application.availableFrom ||
                                selectedApplication?.availableTo !== application.availableTo ||
                                selectedApplication?.country !== application.country ||
                                selectedApplication?.city !== application.city)
                        ) {
                            trigger().then(isValid => {
                                if (isValid) {
                                    setIsUpdatingApplication(true)
                                    updateApplication(selectedApplication.applicationId, application)
                                        .then(data => {
                                            const currentApplicationData: ApplicationData = {
                                                ...selectedApplication,
                                                ...data,
                                                currentStep: selectedApplication.currentStep,
                                                isUpdatingCv: false,
                                            }
                                            dispatch({ type: SET_STEP, payload: currentApplicationData })
                                        })
                                        .catch(err => {
                                            const currentApplicationData = {
                                                ...selectedApplication,
                                                cvFileName,
                                                ...application,
                                                isUpdatingCv: false,
                                            }
                                            dispatch({ type: SET_STEP, payload: currentApplicationData })
                                            switch (err.data.code) {
                                                case 'REGION_NOT_MATCHING':
                                                case 'COUNTRY_NOT_MATCHING':
                                                    setError('country', {
                                                        message: LOCATION_MESSAGE,
                                                    })
                                                    changeFocus('country')
                                                    break
                                                case 'CITIES_NOT_MATCHING':
                                                    setError('city', {
                                                        message: LOCATION_MESSAGE,
                                                    })
                                                    changeFocus('city')
                                                    break
                                                default:
                                                    log(err)
                                                    addError()
                                                    break
                                            }
                                        })
                                        .finally(() => setIsUpdatingApplication(false))
                                }
                            })
                        }
                    })
            }
        },
        [addError, log, selectedApplication, showApplicationCvDropzone, state.selectedApplicationId],
    )

    const showCvPreview = useCallback(() => {
        if (state.selectedApplicationId) {
            getApplicationCv(state.selectedApplicationId)
                .then(response => {
                    saveAs(response, selectedApplication?.cvFileName)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, log, selectedApplication?.cvFileName, state.selectedApplicationId])

    const {
        CompleteInformationForm,
        getValues: getCompleteInformationFormValues,
        trigger: triggerCompleteInformationForm,
        isDirty: isCompleteInformationFormDirty,
        hasErrors: hasCompleteInformationFormErrors,
        setError: setCompleteInformationFormError,
        changeFocus: setCompleteInformationFormFocus,
    } = useCompleteInformationForm(
        selectedApplication,
        handleApplicationCvChange,
        showApplicationCvDropzone,
        showCvPreview,
        opportunity,
        isVmcCloud,
    )
    // We have checkbox for acknowledging that custom cv template was used, but it's only needed on FE side (for validation purposes).
    const getCompleteInformationFormData = useCallback(
        () => ({ ...getCompleteInformationFormValues(), useCvTemplate: undefined }),
        [getCompleteInformationFormValues],
    )

    const { RateSkillsForm, getValues: getRateSkillsFormValues, isDirty: isRateSkillsFormDirty } = useRateSkillsForm(selectedApplication)

    const handleRemoveApplication = useCallback(
        (applicationId: string) => {
            setIsSendingApplication(true)
            removeApplication(applicationId)
                .then(() => {
                    dispatch({ type: REMOVE, payload: applicationId })
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => setIsSendingApplication(false))
        },
        [addError, log],
    )

    const handleApplyWithSpecialist = useCallback(
        (specialistId: string) => {
            if (opportunityId) {
                setIsSendingApplication(true)
                applyWithSpecialist(opportunityId, specialistId)
                    .then(data =>
                        dispatch({
                            type: ADD,
                            payload: {
                                newApplicationData: data,
                                currentApplicationData: selectedApplication
                                    ? { ...selectedApplication, ...getCompleteInformationFormData(), ...getRateSkillsFormValues() }
                                    : null,
                            },
                        }),
                    )
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => setIsSendingApplication(false))
            }
        },
        [addError, getCompleteInformationFormData, getRateSkillsFormValues, log, opportunityId, selectedApplication],
    )

    const handleApplyWithCv = useCallback(
        (files: Array<File> | File) => {
            if (opportunityId && ((Array.isArray(files) && files.length > 0) || (!Array.isArray(files) && files))) {
                setIsSendingApplication(true)
                const cvsData = new FormData()
                if (Array.isArray(files)) {
                    for (const file of files) {
                        cvsData.append('file', file)
                    }
                } else {
                    cvsData.append('file', files)
                }
                applyWithCv(opportunityId, cvsData)
                    .then(data =>
                        dispatch({
                            type: ADD,
                            payload: {
                                newApplicationData: data,
                                currentApplicationData: selectedApplication
                                    ? { ...selectedApplication, ...getCompleteInformationFormData(), ...getRateSkillsFormValues() }
                                    : null,
                            },
                        }),
                    )
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => setIsSendingApplication(false))
            }
        },
        [addError, getCompleteInformationFormData, getRateSkillsFormValues, log, opportunityId, selectedApplication],
    )

    const handleSelectApplication = useCallback(
        (applicationId: Nullable<string>) => {
            const currentApplicationData = selectedApplication
                ? { ...selectedApplication, ...getCompleteInformationFormData(), ...getRateSkillsFormValues() }
                : null
            dispatch({
                type: SELECT_APPLICATION,
                payload: { applicationId, currentApplicationData },
            })
        },
        [getCompleteInformationFormData, getRateSkillsFormValues, selectedApplication],
    )

    const handlePrevious = useCallback(() => {
        if (selectedApplication) {
            if (selectedApplication.currentStep === CurrentStep.RATE_SKILLS) {
                if (isRateSkillsFormDirty) {
                    setIsUpdatingApplication(true)
                    updateApplicationSkills(selectedApplication.applicationId, getRateSkillsFormValues())
                        .then(data => {
                            const currentApplicationData: ApplicationData = {
                                ...selectedApplication,
                                ...data,
                                currentStep: getPreviousStep(selectedApplication.currentStep),
                            }
                            dispatch({ type: SET_STEP, payload: currentApplicationData })
                        })
                        .catch(err => {
                            log(err)
                            addError()
                        })
                        .finally(() => setIsUpdatingApplication(false))
                } else {
                    const currentApplicationData: ApplicationData = {
                        ...selectedApplication,
                        currentStep: getPreviousStep(selectedApplication.currentStep),
                    }
                    dispatch({ type: SET_STEP, payload: currentApplicationData })
                }
            } else {
                const currentApplicationData: ApplicationData = {
                    ...selectedApplication,
                    currentStep: getPreviousStep(selectedApplication.currentStep),
                }
                dispatch({ type: SET_STEP, payload: currentApplicationData })
            }
        }
    }, [addError, getRateSkillsFormValues, isRateSkillsFormDirty, log, selectedApplication])

    const handleNext = useCallback(() => {
        if (selectedApplication) {
            if (selectedApplication.currentStep === CurrentStep.COMPLETE_INFORMATION) {
                if (!hasCompleteInformationFormErrors) {
                    triggerCompleteInformationForm().then(isValid => {
                        if (isValid) {
                            if (isCompleteInformationFormDirty) {
                                const completeInformationFormValues = getCompleteInformationFormData()
                                setIsUpdatingApplication(true)
                                updateApplication(selectedApplication.applicationId, completeInformationFormValues)
                                    .then(data => {
                                        const currentApplicationData: ApplicationData = {
                                            ...selectedApplication,
                                            ...data,
                                            currentStep: getNextStep(selectedApplication.currentStep),
                                        }
                                        dispatch({ type: SET_STEP, payload: currentApplicationData })
                                    })
                                    .catch(err => {
                                        switch (err.data.code) {
                                            case 'REGION_NOT_MATCHING':
                                            case 'COUNTRY_NOT_MATCHING':
                                                setCompleteInformationFormError('country', {
                                                    message: LOCATION_MESSAGE,
                                                })
                                                setCompleteInformationFormFocus('country')
                                                break
                                            case 'CITIES_NOT_MATCHING':
                                                setCompleteInformationFormError('city', {
                                                    message: LOCATION_MESSAGE,
                                                })
                                                setCompleteInformationFormFocus('city')
                                                break
                                            default:
                                                log(err)
                                                addError()
                                                break
                                        }
                                    })
                                    .finally(() => setIsUpdatingApplication(false))
                            } else {
                                const currentApplicationData: ApplicationData = {
                                    ...selectedApplication,
                                    currentStep: getNextStep(selectedApplication.currentStep),
                                }
                                dispatch({ type: SET_STEP, payload: currentApplicationData })
                            }
                        }
                    })
                }
            } else if (selectedApplication.currentStep === CurrentStep.RATE_SKILLS) {
                if (isRateSkillsFormDirty) {
                    setIsUpdatingApplication(true)
                    updateApplicationSkills(selectedApplication.applicationId, getRateSkillsFormValues())
                        .then(data => {
                            const currentApplicationData: ApplicationData = {
                                ...selectedApplication,
                                ...data,
                                currentStep: getNextStep(selectedApplication.currentStep),
                            }
                            dispatch({ type: SET_STEP, payload: currentApplicationData })
                        })
                        .catch(err => {
                            log(err)
                            addError()
                        })
                        .finally(() => setIsUpdatingApplication(false))
                } else {
                    const currentApplicationData: ApplicationData = {
                        ...selectedApplication,
                        currentStep: getNextStep(selectedApplication.currentStep),
                    }
                    dispatch({ type: SET_STEP, payload: currentApplicationData })
                }
            } else {
                if (opportunityId) {
                    setIsUpdatingApplication(true)
                    applyWithApplication(opportunityId, selectedApplication.applicationId)
                        .then(data => {
                            const currentApplicationData: ApplicationData = {
                                ...selectedApplication,
                                ...data,
                            }
                            dispatch({ type: SET_STEP, payload: currentApplicationData })
                        })
                        .catch(err => {
                            log(err)
                            addError()
                        })
                        .finally(() => setIsUpdatingApplication(false))
                }
            }
        }
    }, [
        addError,
        hasCompleteInformationFormErrors,
        getCompleteInformationFormData,
        getRateSkillsFormValues,
        isCompleteInformationFormDirty,
        isRateSkillsFormDirty,
        log,
        opportunityId,
        selectedApplication,
        setCompleteInformationFormError,
        setCompleteInformationFormFocus,
        triggerCompleteInformationForm,
    ])

    const isMobile = useIsMobile()

    const handleGoBack = useCallback(() => {
        if (isMobile && state.selectedApplicationId) {
            handleSelectApplication(null)
        } else {
            history.push(`/open-opportunities/${opportunityId}`)
        }
    }, [handleSelectApplication, isMobile, opportunityId, state.selectedApplicationId])

    useEffect(() => {
        if (opportunityId) {
            getOpportunityApplications(opportunityId)
                .then(data =>
                    dispatch({ type: INIT, payload: { applications: data.applications, selectedApplicationId: applicationIdParam } }),
                )
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, log, opportunityId, applicationIdParam])

    const nextApplicationId = useMemo(
        () => state.applications.find(application => !application.appliedOn)?.applicationId,
        [state.applications],
    )

    const handleApplyWithNextSpecialist = useCallback(() => {
        if (nextApplicationId) {
            handleSelectApplication(nextApplicationId)
        }
    }, [handleSelectApplication, nextApplicationId])

    useEffect(() => {
        if (
            shareCandidate &&
            selectedApplication?.specialistId &&
            !selectedApplication?.appliedOn &&
            !selectedApplication.isRelatedSpecialistFetched
        ) {
            setIsUpdatingApplication(true)
            getShareMySpecialistsSpecialist(selectedApplication.specialistId)
                .then(({ availableFrom, currency, rate }) => {
                    dispatch({
                        type: SELECT_APPLICATION,
                        payload: {
                            applicationId: selectedApplication.applicationId,
                            currentApplicationData: {
                                ...selectedApplication,
                                isRelatedSpecialistFetched: true,
                                ...(!selectedApplication.availableFrom && { availableFrom }),
                                ...(!selectedApplication.preferredRate &&
                                    currency === selectedApplication.currency && { preferredRate: rate }),
                            },
                        },
                    })
                })
                .catch(log)
                .finally(() => {
                    setIsUpdatingApplication(false)
                })
        }
    }, [selectedApplication?.specialistId, log, shareCandidate, selectedApplication])

    const handleSharedStatus = useCallback((application: ApplicationData) => {
        dispatch({
            type: SELECT_APPLICATION,
            payload: {
                applicationId: application.applicationId,
                currentApplicationData: application,
            },
        })
    }, [])

    const stepIndicatorStyles = useCallback(
        (status: StepStatus) =>
            css`
                margin-right: 0;
                ${mqMin[3]} {
                    margin-right: 8px;
                }
                ${status === StepStatus.ACTIVE
                    ? `color:${theme.colors.white};background-color:${theme.colors.violet_5};`
                    : status === StepStatus.DONE
                    ? `background-color:transparent;img {filter:${blackToWhiteFilter} ${blackToVioletDarkFilter};}`
                    : ''}
            `,
        [theme.colors.violet_5, theme.colors.white],
    )

    return (
        <SubscriptionLayer visible={payments && shouldShowWarningBanner}>
            <div
                css={css`
                    width: 100vw;
                    height: 100vh;
                    position: relative;
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    > * ::-webkit-scrollbar {
                        display: none;
                    }
                `}
            >
                <div
                    css={css`
                        padding: 16px;
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        border: 1px solid ${theme.colors.violet_2};
                        box-shadow: ${theme.shadow.small};
                        background-color: ${theme.colors.white};
                        position: relative;
                        z-index: 1;
                    `}
                >
                    <IconButton
                        name='back'
                        size={24}
                        styles={css`
                            margin-right: 24px;
                        `}
                        onClick={handleGoBack}
                        dataTestId='back'
                    />
                    {isMobile
                        ? state.selectedApplicationId
                            ? 'Back to My Candidates '
                            : 'Back to Opportunity'
                        : 'Applying to opportunity'}
                </div>
                <div
                    css={css`
                        display: flex;
                        height: calc(100vh - 58px);
                    `}
                >
                    <CandidatesListColumn
                        applications={state.applications}
                        handleSelectApplication={handleSelectApplication}
                        handleRemoveApplication={handleRemoveApplication}
                        selectedApplicationId={state.selectedApplicationId}
                        isSendingApplication={isSendingApplication}
                        handleApplyWithSpecialist={handleApplyWithSpecialist}
                        handleApplyWithCv={handleApplyWithCv}
                        opportunityId={opportunityId}
                    />

                    <div
                        css={css`
                            flex: 1;
                            height: calc(100vh - 58px);
                            position: relative;
                        `}
                    >
                        {isUpdatingApplication && (
                            <FlexBox
                                css={css`
                                    background-color: ${theme.colors.beige_1};
                                    height: calc(100vh - 58px);
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
                                `}
                            >
                                <ContentLoader />
                            </FlexBox>
                        )}
                        <div
                            css={
                                isUpdatingApplication &&
                                css`
                                    display: none;
                                `
                            }
                        >
                            {!state.selectedApplicationId ? (
                                <FlexBox
                                    css={css`
                                        background-color: ${theme.colors.beige_1};
                                        height: calc(100vh - 58px);
                                        align-items: center;
                                        justify-content: center;
                                        flex-direction: column;
                                    `}
                                >
                                    {isSendingApplication ? (
                                        <ContentLoader />
                                    ) : (
                                        <Fragment>
                                            <div
                                                css={css`
                                                    background-color: ${theme.colors.beige_3};
                                                    border-radius: 50%;
                                                    padding: 15px;
                                                `}
                                            >
                                                <img
                                                    src={userImage}
                                                    alt='user'
                                                    css={css`
                                                        width: 30px;
                                                        height: 30px;
                                                    `}
                                                />
                                            </div>
                                            <p
                                                css={css`
                                                    margin-top: 24px;
                                                    color: ${theme.colors.gray_3};
                                                `}
                                            >
                                                Select a Candidate first
                                            </p>
                                        </Fragment>
                                    )}
                                </FlexBox>
                            ) : selectedApplication?.appliedOn ? (
                                <div
                                    css={css`
                                        background-color: ${theme.colors.beige_1};
                                        padding: 16px;
                                        height: calc(100vh - 58px);
                                        overflow-y: scroll;
                                    `}
                                >
                                    <div
                                        css={css`
                                            max-width: 590px;
                                            margin: 0 auto;
                                        `}
                                    >
                                        <ApplicationSuccess
                                            application={selectedApplication}
                                            handleApplyWithNextSpecialist={nextApplicationId ? handleApplyWithNextSpecialist : undefined}
                                            handleSharedStatus={handleSharedStatus}
                                            isVmcCloud={isVmcCloud}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <Fragment>
                                    <div
                                        css={css`
                                            background-color: ${theme.colors.beige_1};
                                            padding: 24px 16px;
                                            height: calc(100vh - 130px);
                                            overflow-y: scroll;
                                        `}
                                    >
                                        <div
                                            css={css`
                                                max-width: 590px;
                                                margin: 0 auto;
                                            `}
                                        >
                                            <StepsIndicator
                                                steps={[
                                                    {
                                                        name: steps[0],
                                                        status:
                                                            selectedApplication?.currentStep === CurrentStep.COMPLETE_INFORMATION
                                                                ? StepStatus.ACTIVE
                                                                : StepStatus.DONE,
                                                    },
                                                    {
                                                        name: steps[1],
                                                        status:
                                                            selectedApplication?.currentStep === CurrentStep.COMPLETE_INFORMATION
                                                                ? StepStatus.PENDING
                                                                : selectedApplication?.currentStep === CurrentStep.RATE_SKILLS
                                                                ? StepStatus.ACTIVE
                                                                : StepStatus.DONE,
                                                    },
                                                    {
                                                        name: steps[2],
                                                        status:
                                                            selectedApplication?.currentStep === CurrentStep.COMPLETE_INFORMATION
                                                                ? StepStatus.PENDING
                                                                : selectedApplication?.currentStep === CurrentStep.RATE_SKILLS
                                                                ? StepStatus.PENDING
                                                                : StepStatus.ACTIVE,
                                                    },
                                                ]}
                                                styles={css`
                                                    background-color: transparent;
                                                    width: 100%;
                                                    justify-content: space-between;
                                                    flex-wrap: nowrap;
                                                    padding: 0 0 24px;
                                                `}
                                                stepStyles={css`
                                                    flex-direction: column;
                                                    text-align: center;
                                                    padding: 0;
                                                    ${mqMin[3]} {
                                                        flex-direction: row;
                                                    }
                                                `}
                                                dividerStyles={css`
                                                    margin: 0 4px;
                                                `}
                                                stepIndicatorStyles={stepIndicatorStyles}
                                            />
                                            {selectedApplication?.currentStep === CurrentStep.COMPLETE_INFORMATION ? (
                                                CompleteInformationForm
                                            ) : selectedApplication?.currentStep === CurrentStep.RATE_SKILLS ? (
                                                RateSkillsForm
                                            ) : (
                                                <ApplicationSummary
                                                    application={selectedApplication}
                                                    showCvPreview={showCvPreview}
                                                    isVmcCloud={isVmcCloud}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <FlexBox
                                        alignItems='center'
                                        justifyContent='flex-end'
                                        css={css`
                                            height: 72px;
                                            padding: 16px;
                                        `}
                                    >
                                        {selectedApplication?.currentStep !== CurrentStep.COMPLETE_INFORMATION && (
                                            <Button variant='text' onClick={handlePrevious} dataTestId='application-wizard-prev'>
                                                Previous
                                            </Button>
                                        )}
                                        <Button
                                            style={css`
                                                width: 200px;
                                                margin-left: 16px;
                                            `}
                                            onClick={handleNext}
                                            dataTestId='application-wizard-next'
                                        >
                                            {selectedApplication?.currentStep === CurrentStep.REVIEW_AND_APPLY ? 'Apply' : 'Next'}
                                        </Button>
                                    </FlexBox>
                                </Fragment>
                            )}
                        </div>
                    </div>
                    {opportunity && (
                        <OpportunitySummaryColumn
                            opportunityId={opportunityId}
                            opportunity={opportunity}
                            applications={state.applications.filter(application => application.appliedOn)}
                        />
                    )}
                </div>
            </div>
        </SubscriptionLayer>
    )
}

export { ApplicationWizard }
