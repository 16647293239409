/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ContentLoader } from 'src/components/layout/Loader'
import { TooltipIcon } from 'src/components/TooltipIcon'
import { SharedType } from 'src/contracts/specialist/specialist'
import { ReduxContext } from 'src/redux/Store'
import { Button } from 'src/components/Button'
import { Icon } from 'src/components/Icon'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { getAppPath } from 'src/contracts/applications'
import { history } from 'src/history'
import { convertTimestampToDateString } from 'src/utils/dates'
import { useLogger } from 'src/utils/useLogger'
import { addCvToParsing, getShareMySpecialistsSpecialist } from '../../api/open-opportunities/api'

import { ShareCandidateModal } from './ShareCandidateModal'
import { MsaInfo } from 'src/components/opportunities/MsaInfo'
import { useCompany } from 'src/hooks/useCompany'
import { ApplicationData } from './ApplicationWizard/redux/types'

type ApplicationSuccessProps = {
    application: ApplicationData
    handleApplyWithNextSpecialist?: () => void
    handleSharedStatus: (application: ApplicationData) => void
    isVmcCloud?: boolean
}

const ApplicationSuccess: FunctionComponent<React.PropsWithChildren<ApplicationSuccessProps>> = ({
    application,
    handleApplyWithNextSpecialist,
    handleSharedStatus,
    isVmcCloud = false,
}) => {
    const {
        selectors: {
            featureFlags: { shareCandidate },
        },
    } = useContext(ReduxContext)

    const { company } = useCompany()

    const [isLoading, setLoading] = useState(false)
    const [shareCandidateModalVisible, setShareCandidateModalVisible] = useState<boolean>(false)
    const log = useLogger('error')
    const { addError } = useNotifications()
    const theme = useTheme()
    const goToApplicationDetails = useCallback(() => {
        history.push(`${getAppPath('OPEN_OPPORTUNITIES')}/applications/${application.applicationId}`)
    }, [application.applicationId])
    const createProfile = useCallback(() => {
        if (shareCandidate) {
            history.push(`${getAppPath('MY_SPECIALISTS')}/specialist/${application.specialistId}`)
        } else {
            addCvToParsing(application.applicationId)
                .then(() => {
                    history.push(`${getAppPath('MY_SPECIALISTS')}/cv-parsing#confirm-data`)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, application.applicationId, application.specialistId, log, shareCandidate])

    useEffect(() => {
        if (shareCandidate && application.specialistId && application.isNotShared === undefined) {
            setLoading(true)
            getShareMySpecialistsSpecialist(application.specialistId)
                .then(({ sharedStatus }) => {
                    handleSharedStatus({ ...application, isNotShared: sharedStatus === SharedType.NOT_SHARED })
                })
                .catch(log)
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [application, application.specialistId, handleSharedStatus, log, shareCandidate])

    const toggleShareCandidateModal = useCallback(() => {
        setShareCandidateModalVisible(wasShareCandidateModalVisible => !wasShareCandidateModalVisible)
    }, [])

    const shouldShowSMEWarning = useMemo(
        () => !company?.msaSigned && !application.smeCloud && !isVmcCloud,
        [application.smeCloud, company?.msaSigned, isVmcCloud],
    )

    return (
        <Fragment>
            {isLoading ? (
                <FlexBox
                    css={css`
                        background-color: ${theme.colors.beige_1};
                        height: calc(100vh - 58px);
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                    `}
                >
                    <ContentLoader />
                </FlexBox>
            ) : (
                <FlexBox
                    css={css`
                        flex-direction: column;
                        align-items: center;
                    `}
                >
                    <Icon name='success' size={100} />
                    <h5
                        css={css`
                            text-align: center;
                        `}
                        data-testid='application-success'
                    >
                        You’ve applied successfully!
                    </h5>
                    <div
                        css={css`
                            background-color: ${theme.colors.green_2};
                            padding: 4px 8px;
                            font-weight: 500;
                            border-radius: 4px;
                            margin-bottom: 24px;
                        `}
                    >
                        Applied on: {convertTimestampToDateString(application.appliedOn)}
                    </div>
                    {shouldShowSMEWarning ? (
                        <MsaInfo />
                    ) : (
                        <p
                            css={css`
                                text-align: center;
                                max-width: 490px;
                                margin: 0;
                            `}
                        >
                            We've received your application for{' '}
                            <strong>
                                {application.firstName} {application.lastName}
                            </strong>{' '}
                            and will get back to you with feedback within <strong>2 business days.</strong>
                        </p>
                    )}
                    {!shareCandidate && handleApplyWithNextSpecialist && (
                        <Button
                            style={css`
                                margin-top: 24px;
                            `}
                            onClick={handleApplyWithNextSpecialist}
                        >
                            Apply with next Specialist
                        </Button>
                    )}
                    {shareCandidate && application.specialistId && application.isNotShared && (
                        <FlexBox
                            alignItems='center'
                            css={css`
                                margin-top: 24px;
                            `}
                        >
                            <Button onClick={toggleShareCandidateModal}>Share this specialist</Button>
                            <Tooltip
                                title='Quickly share this specialist to Talent Marketplace to get more project offers from clients.'
                                css={css`
                                    margin-left: 10px;
                                `}
                            >
                                <span>
                                    <TooltipIcon />
                                </span>
                            </Tooltip>
                        </FlexBox>
                    )}
                    <div
                        css={css`
                            border-radius: 4px;
                            border: 1px solid ${theme.colors.violet_2};
                            background-color: ${theme.colors.white};
                            width: 100%;
                            padding: 24px;
                            margin-top: 48px;
                        `}
                    >
                        <FlexBox
                            justifyContent='space-between'
                            alignItems='center'
                            css={css`
                                width: 100%;
                            `}
                        >
                            <FlexBox>
                                <div
                                    css={css`
                                        padding: 10px;
                                        background-color: ${theme.colors.violet_2};
                                        border-radius: 20px;
                                        width: 40px;
                                        height: 40px;
                                        box-shadow: ${theme.shadow.small};
                                    `}
                                >
                                    <Icon name='waving-flag' size={20} />
                                </div>
                                <div
                                    css={css`
                                        margin-left: 16px;
                                    `}
                                >
                                    <h6
                                        css={css`
                                            margin: 0;
                                        `}
                                    >
                                        Status
                                    </h6>
                                    <div
                                        css={css`
                                            color: ${theme.colors.gray_4};
                                        `}
                                    >
                                        Track {application.firstName}’s recruitment status
                                    </div>
                                </div>
                            </FlexBox>
                            <Button variant='linkForm' onClick={goToApplicationDetails}>
                                <FlexBox alignItems='center'>
                                    Check status <Icon name='arrow-right-single' />
                                </FlexBox>
                            </Button>
                        </FlexBox>
                    </div>
                    {((shareCandidate && application.specialistId) || (!shareCandidate && !application.specialistId)) && (
                        <div
                            css={css`
                                border-radius: 4px;
                                border: 1px solid ${theme.colors.violet_2};
                                background-color: ${theme.colors.white};
                                width: 100%;
                                padding: 24px;
                                margin-top: 16px;
                            `}
                        >
                            <FlexBox
                                justifyContent='space-between'
                                alignItems='center'
                                css={css`
                                    width: 100%;
                                `}
                            >
                                <FlexBox>
                                    <div
                                        css={css`
                                            padding: 10px;
                                            background-color: ${theme.colors.violet_2};
                                            border-radius: 20px;
                                            width: 40px;
                                            height: 40px;
                                            box-shadow: ${theme.shadow.small};
                                        `}
                                    >
                                        <Icon name='user' size={20} />
                                    </div>
                                    <div
                                        css={css`
                                            margin-left: 16px;
                                        `}
                                    >
                                        <h6
                                            css={css`
                                                margin: 0;
                                            `}
                                        >
                                            {shareCandidate ? 'View specialist info' : 'Create Profile'}
                                        </h6>
                                        <div
                                            css={css`
                                                color: ${theme.colors.gray_4};
                                            `}
                                        >
                                            {shareCandidate ? 'View and edit this specialist' : 'Save profile in My Specialists App'}
                                        </div>
                                    </div>
                                </FlexBox>
                                <Button variant='linkForm' onClick={createProfile} dataTestId='create-profile'>
                                    <FlexBox alignItems='center'>
                                        {shareCandidate ? 'Go to My Specialists' : 'Finish creating profile'}{' '}
                                        <Icon name='arrow-right-single' />
                                    </FlexBox>
                                </Button>
                            </FlexBox>
                        </div>
                    )}
                    {shareCandidateModalVisible && (
                        <ShareCandidateModal
                            onClose={toggleShareCandidateModal}
                            application={application}
                            handleSharedStatus={handleSharedStatus}
                        />
                    )}
                </FlexBox>
            )}
        </Fragment>
    )
}

export { ApplicationSuccess }
